import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Layout } from '../components/layout'
import { Seo } from '../components/seo'
import { HeroPanel } from '../components/heroPanel'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          tagline
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Home" description=""/>
      <HeroPanel
        imageAlt={data.site.siteMetadata?.title}
        title={data.site.siteMetadata?.title}
        tagline={data.site.siteMetadata?.tagline}
      />
    </Layout>
  )
}

export default IndexPage
