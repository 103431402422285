import React, { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Reward, { RewardElement } from 'react-rewards'

import './heroPanel.scss'

type Props = {
  imageAlt: string;
  title: string;
  tagline: string;
}

export const HeroPanel: React.FC<Props> = ({ imageAlt, tagline, title }: Props) => {
  let reward: RewardElement | null = null;

  useEffect(() => {
    reward && reward.rewardMe()
  }, [reward])

  return (
    <div className="hero-panel">
      <StaticImage
        src={`../images/hero.png`}
        quality={95}
        alt={imageAlt}
      />
      <div className="positioner">
        <Reward
          ref={(ref) => { reward = ref }}
          type='memphis'
        />
      </div>
      <h1>{title}</h1>
      <h2>{tagline}</h2>
    </div>
  )
}